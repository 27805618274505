@import './colors';

@keyframes cursor {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
        
}

#cursorSymbol {
    animation: cursor 1s infinite
}

span {
    color: $subtitle-colour;
}