@import "./fontSizes";
@import "./Route";

#filter {
    display: flex;
    column-gap: 2vw;
    align-items: center;
    margin: 3vw 0;

    #title {
        font-size: $title-size;
    }
}