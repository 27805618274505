@import "./colors";
@import "./fontSizes";

#open-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

#dropdown-menu {
    display: none;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    // min-width: 160px;
    right: 10px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

#menu-button {
    background-color: white;
    border: none;
    border-radius: 2px;

    text-decoration: none;

    i {
        font-size: $body-size;
        color: black;
        font-size: $body-size;
        padding: 10px;
    }

    &:hover {
        background-color: $hovering-colour;

        i {
            color: white;
        }
    }
}

#open-menu, #dropdown-menu {
    a {
        color: $title-colour;

        text-decoration: none;
        flex-grow: 1;

        &:last-child {
            padding-right: 0;
        }

        padding-right: 1vw;

        &:hover {
            color: $hovering-colour;
        }

        // This does not refer to the :active pseudoclass
        &.active {
            color: $active-colour;
        }
    }
}

@media (max-width: 1100px) {
    #open-menu     { display: none; }
    #dropdown-menu { display: inline-block; }
}