@import "./colors";
@import "./fontSizes";

#container {
    padding: 0 1vw;
    z-index: 1;
    position: sticky;
    top: 0;
    background-color: #ffffff;
    // margin: 0.5vw 0;

    hr {
        border-color: $border-colour;
    }
}

#header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    
    // margin: 0.5vw 0;
    min-height: 3.5vw;
    
    font-size: $title-size;
    color: $title-colour;

    .name {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        align-items: center;

        @media (max-width: 1100px) {
            flex-direction: column;
            align-items: start;
        }

        div:first-child {
            padding-right: 0.5vw;
        }
    }
}