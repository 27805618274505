@import "./colors";
@import "./fontSizes";
@import "./Route";

.about {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 7vw;
    align-items: center;
    margin-bottom: 3vw;
}

#left {
    padding: 0 8vw;
    align-items: center;
    flex-basis: content;
    flex-grow: 0;

    @media (max-width: 700px) {
        padding: 0;
    }

    #intro {
        & > h1 {
            @media (min-width: 700px) {
                font-size: 3rem;
            }
            text-align: center;
            margin: 0;
        }
    }

    #punchline {
        margin: calc(3vw + 10px) 0;
        text-align: center;
        font-weight: normal;
    }
}

#highlights {
    padding: 0 4vw;

    table td + td { border-left:5px dotted gray; }

    thead th h2 {
            margin-top: 0;
    }

    tbody td {
        width: 50%;
        padding: 3vw;
        text-align: center;
    }
}

#navigation {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: calc(1vw + 10px);

    a {
        flex-basis: content;
        flex-grow: 0;
    }

    button {
        color: white;
        background-color: black; 
    
        border: none;
        border-radius: 4px;
        padding: 15px 32px;
    
        text-decoration: none;
    
        font-size: $body-size;

        &:hover {
            background-color: $hovering-colour;
        }
    }
}

@media only screen and (max-width: 700px) {
    #highlights {
        padding: 0;
    }
}

@media only screen and (max-width: 700px) {
    #big {
        display: none;
    }
}

@media only screen and (min-width: 700px) {
    #small {
        display: none;
    }
}
