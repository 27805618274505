@import "../styles/colors";
@import "../styles/fontSizes";

.card {
    border-color: $border-colour;
    border-style: solid;
    border-width: 2px;
    border-radius: 5px;

    padding: 2vw;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;


    h4 {
        text-align: center;
        font-size: $card-title-size;
        margin: 0.2vw 0 1vw 0;

        a {
            text-decoration: none;
            color: $text-colour;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .description {
        font-size: $body-size;
        padding: 2vw 0;
        align-self: flex-start;

        #company {
            font-weight: bold;
            padding-bottom: 0.5vw;
        }
    }
}