@import "./colors.scss";
@import "./fontSizes";

.skillsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    gap: 0.5vw;
    align-self: flex-start;
}

.skill {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: content;

    justify-content: center;
    align-items: center;
    
    border-color: $border-colour;
    border-style: solid;
    border-width: 1.5px;
    border-radius: 5px;

    p {
        font-size: $body-size;
        text-align: center;
        padding: 0.5vw;
        margin: 0;
    }
}