@import "./fontSizes";
@import "./colors";

#contact {
    padding: 2vw;
    display: flex;
    gap: 2vw;
    margin: 3vw 0;

    @media (max-width: 700px) {
        flex-direction: column;
    }
}

#links {
    flex-grow: 0.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 700px) {
        flex-direction: row;
    }

    h1 {
        margin: 0 0 2vw 0;
    }

    div {
        margin: 2vw 0;

        &:hover {
            background-color: $hovering-colour;
        }
    }
}


#form {
    flex-grow: 1;
}


#contact-form {
    padding: 2vw;
    border: 2px solid;
    border-radius: 5px;

    hr {
        border-bottom: none;
        border-top-style: dotted;
    }

    .field {
        display: flex;
        align-items: center;
        gap: 0.5vw;
        justify-content: end;

        .input {
            flex-grow: 1;
            border: 0;
            font-size: $body-size;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

            &:focus-visible {
                outline: none;
            }
        }

        .input[type='submit'] {
            flex-grow: 0;

            color: white;
            background-color: black; 
        
            border: none;
            border-radius: 4px;
            padding: 10px 20px;
        
            text-decoration: none;
        
            font-size: $body-size;

            &:hover {
                background-color: $hovering-colour;
            }
        }
    }
}

.message {
    padding: 2vw;
    margin-bottom: 1vw;

    border: 2px solid;
    border-radius: 5px;

    text-align: center;
    font-size: $body-size;
}