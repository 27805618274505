@import "./colors";

#achievement-container {
    padding: 0 4vw;

    .achievement {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 2vw 0;
        align-items: center;

        @media (max-width: 900px) {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        & > img {
            justify-self: end;
            padding-right: 2vw;

            @media (max-width: 600px) {
                width: 80%;
                padding-right: 0;
            }
        }

        & > div {
            justify-self: start;
            padding-left: 2vw;

            a, h2 {
                text-decoration: none;
                color: $text-colour;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}